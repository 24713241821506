@use 'sass:math';
@use '@angular/material' as mat;

$header-height: $right-drawer-entity-header-height;
$mat-progress-bar-zindex: 2;

.right-drawer-sidenav {
	top: $toolbar-height !important;
	transition: min-width $transition-props;
	will-change: min-width, transform;
	min-width: 750px;

	&.fullscreen {
		min-width: 100vw !important;

		.backdrop {
			content: '';
			@include absolute-spread();
			background: $color-backdrop;
			z-index: 4;
		}

		.right-drawer-header > nav,
		.right-drawer-content .right-drawer-body {
			transform: translateX(-20%);
		}
	}

	&.occupies-most-of-space {
		min-width: 70vw;

		.right-drawer-content {
			.right-drawer-body .right-drawer-body-header,
			.right-drawer-body .mat-stepper-horizontal,
			.right-drawer-body .right-drawer-alert,
			.card {
				max-width: $right-drawer-card-max-width-lg;
			}
		}
	}

	&.compact {
		min-width: 450px;
	}

	&.with-navigation-level {
		bp-right-drawer:not(.first-loading):not(.drawer-type-load-error) & {
			$navigation-level-height: 55px;
			$overallheight: $header-height + $navigation-level-height;

			.mat-drawer-inner-container {
				height: calc(100% - #{$overallheight});
				margin-top: $overallheight;
			}

			.right-drawer-header {
				height: $overallheight;

				&:after {
					content: unset !important;
				}

				> .right-drawer-subheader {
					background: white;
					z-index: 3;
					@include afterBottomBorder();
				}

				> nav {
					@include elevation($elevation-panel);
					transition: transform $transition-props;
					margin: 0 (-$header-height);
					border-bottom: none;
					z-index: 2;

					.mat-tab-link {
						height: $navigation-level-height;
						font-size: $subheading-2-font-size;
						font-weight: $subheading-2-font-weight;
						line-height: $subheading-2-line-height;
					}
				}
			}

			bp-right-drawer-entity > .mat-progress-bar,
			> .mat-progress-bar {
				top: $overallheight !important;
			}
		}
	}

	&.headless {
		.mat-drawer-inner-container {
			height: 100%;
			margin-top: 0;
		}
	}

	.mat-drawer-inner-container {
		height: calc(100% - #{$header-height});
		margin-top: $header-height;

		> .btn-icon-close {
			top: 15px;
			left: 15px;
			z-index: 4;
		}
	}

	.right-drawer-header,
	.right-drawer-content > .mat-tab-group .mat-tab-header {
		@include afterBottomBorder();

		position: absolute;
		top: 0;
		width: 100%;
		left: 0;

		&,
		> .right-drawer-subheader {
			height: $header-height;
		}
	}

	.right-drawer-header.page-header,
	.right-drawer-header {
		background: white;
		z-index: $mat-progress-bar-zindex + 1;

		&,
		> .right-drawer-subheader {
			padding: 0 $header-height;
		}

		> .right-drawer-subheader {
			margin: 0 (-$header-height);
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		h1 {
			margin: 0;
		}

		.right-drawer-header-content {
			display: flex;
		}

		bp-right-drawer-entity-header-buttons,
		bp-right-drawer-header-buttons,
		.buttons {
			margin-left: auto;
			display: flex;
			align-items: center;

			.mat-button-base {
				margin-left: 1rem;
			}
		}

		.mat-button-base:not(.mat-icon-button) {
			@extend .bp-round-button, .bp-button-md;
		}

		bp-input {
			margin-top: -3px;

			.mat-form-field-infix {
				width: 300px;

				input {
					font-size: $right-drawer-entity-headline-size;
					font-weight: $right-drawer-entity-headline-font-weight;
				}
			}
		}
	}

	.right-drawer-content > .mat-tab-group + .right-drawer-header {
		pointer-events: none;
		z-index: 1;

		&:after {
			display: none;
		}

		.buttons {
			pointer-events: auto;
		}
	}

	.right-drawer-body {
		transition: transform $transition-props;
		$body-padding-y: math.div($header-height, 2);
		padding: $body-padding-y $header-height;
		flex: 1;

		&.gray {
			background: $color-bg;
		}

		.right-drawer-body-header {
			max-width: $right-drawer-card-max-width;
			margin: 0 auto;
			margin-bottom: math.div($body-padding-y, 2);

			&:not(:first-child) {
				margin-top: $body-padding-y;
			}
		}

		.mat-stepper-horizontal {
			background: transparent;
			margin: 0 auto;

			.mat-horizontal-stepper-header-container {
				margin: 0 24px;
			}
		}

		.right-drawer-alert {
			max-width: $right-drawer-card-max-width;
			width: 100%;
			margin: 0 auto $right-drawer-card-margin-bottom;

			&,
			> .alert {
				box-shadow: none;
			}
		}
	}

	.right-drawer-content > .mat-tab-group {
		background: $color-bg;
		flex: 1;

		> .mat-tab-header {
			.mat-tab-labels {
				padding: 0 $header-height;
			}
		}

		> .mat-tab-body-wrapper {
			margin: math.div($header-height, 2) $header-height;

			> .mat-tab-body {
				@extend .right-drawer-card;
				@extend .right-drawer-card, .card-view;

				> .mat-tab-body-content {
					padding: 0 5px;
					margin: 0 -5px;
				}
			}
		}
	}

	&:not(.with-navigation-level) {
		.right-drawer-content > .mat-tab-group,
		.mat-tab-nav-bar.right-drawer-header,
		.right-drawer-header > .mat-tab-nav-bar {
			.mat-tab-link,
			.mat-tab-header .mat-tab-label {
				height: $header-height;
				min-width: 130px;
			}
		}
	}

	.mat-tab-nav-bar.right-drawer-header,
	.right-drawer-header > .mat-tab-nav-bar {
		flex: 1;
		border: none;
	}

	bp-dynamic-outlet ~ *,
	.right-drawer-content {
		min-height: 100%;
		display: flex;
		flex-flow: column;
		flex: 1;
	}

	.right-drawer-content {
		min-height: 100%;

		.right-drawer-body .right-drawer-body-header,
		.right-drawer-body .mat-stepper-horizontal,
		.card {
			max-width: $right-drawer-card-max-width;
		}

		&,
		> form,
		.right-drawer-body {
			display: flex;
			flex-flow: column;
			flex: 1;
		}

		bp-right-drawer-entity > .mat-progress-bar,
		> .mat-progress-bar {
			position: absolute;
			top: $header-height !important;
			height: $mat-progress-bar-height;
			z-index: $mat-progress-bar-zindex;
		}
	}

	.card {
		@extend .right-drawer-card;
	}
}

.right-drawer-card {
	display: block;
	background: white;
	border-radius: $card-border-radius;
	border: 1px solid $color-border;
	max-width: $right-drawer-card-max-width;
	margin: 0 auto $right-drawer-card-margin-bottom;

	form > bp-alert {
		margin-top: 0.5rem;
	}

	&.card-view,
	form {
		padding: $right-drawer-card-padding-y $right-drawer-card-padding-x;
	}

	&.card-view {
		.card-top-tab-nav-bar {
			margin: (-$right-drawer-card-padding-y) (-$right-drawer-card-padding-x) $right-drawer-card-padding-y;
		}
	}

	&.card-edit {
		@include elevation($elevation-card);

		.card-top-tab-nav-bar {
			margin-bottom: $right-drawer-card-padding-y;
		}
	}

	&.card-first-add {
		display: flex !important;
		height: 150px;
		align-items: center;
		justify-content: center;
		color: $color-primary;
	}

	&.card-first-add,
	&.card-view-list.can-edit,
	&.card-view.can-edit {
		transition: box-shadow $transition-props;
		cursor: pointer;
		position: relative;

		&:hover {
			@include elevation($elevation-card);
		}
	}

	&.card-view-list,
	&.card-view {
		&.can-edit:after {
			font: 24px/1 'Material Icons Round';
			content: 'edit';
			position: absolute;
			right: 1.1rem;
			top: 1.1rem;
			color: $color-mediumgray;
			animation: fadeIn $transition-props;
			pointer-events: none;
		}

		.row:not(:empty) {
			margin-bottom: 1rem;

			&:last-child {
				margin-bottom: 3px; // 3px for the feature-under-development shadow
			}
		}

		.view-type-text {
			overflow-wrap: break-word;
		}

		.view-type-boolean {
			align-self: center;
		}
	}

	&.card-scrollable-content {
		&.content-scrolled .card-header {
			@include elevation(3);
		}

		&:not(.content-scrolled) .card-list .card-list-item:last-child {
			border-bottom: 1px solid $color-border;
		}

		.card-header {
			transition: box-shadow $transition-props;
			border-bottom: 1px solid $color-border;
		}

		.card-list .card-list-item:first-child {
			border-top: none;
		}
	}

	.row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
		gap: $right-drawer-card-padding-x;

		+ .row {
			margin-top: 0.25rem;
		}

		> * {
			flex: 1;
		}

		> .control.w-100 > * {
			width: 100%;
		}

		> :first-child:not(.control-type-checkbox):not(.control-type-switch) {
			+ .control-type-switch {
				margin-top: 1rem;
			}
			+ .control-type-checkbox {
				margin-top: 1.2rem;
			}
		}

		.control-type-button-toggle {
			margin-bottom: 26px;
		}
	}

	.card-header {
		padding: $right-drawer-card-padding-y $right-drawer-card-padding-x;
		min-height: 60px;
		display: flex;
		align-items: center;

		.control {
			margin-bottom: -19px;
			margin-top: -26px;
		}

		h3 {
			margin-bottom: 0;
		}

		.btn-icon {
			margin: -15px 0;
		}
	}

	&.card-edit,
	&.card-view-list {
		.cdk-virtual-scroll-viewport.card-list {
			height: 65vh;
		}

		&.card-list,
		.card-list {
			&.round-top-border-corners,
			.round-top-border-corners {
				.card-list-item:first-child {
					border-top-left-radius: $card-border-radius;
					border-top-right-radius: $card-border-radius;
				}
			}

			&.round-bottom-border-corners,
			.round-bottom-border-corners {
				.card-list-item:last-child {
					border-bottom-left-radius: $card-border-radius;
					border-bottom-right-radius: $card-border-radius;
				}
			}

			.cdk-drop-list-dragging {
				.card-list-item,
				~ * .card-list-item {
					pointer-events: none;
				}
			}
		}
	}

	.mat-card-content-table {
		margin: 0 (-$right-drawer-card-padding-x) !important;

		th,
		tfoot td {
			padding-bottom: 0.5rem;
			padding-top: 0.5rem;
		}

		tfoot td {
			padding-bottom: 0;
		}
	}

	.card-property-view-caption {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		font-size: 0.85em;
		color: $color-mediumgray;
		margin-bottom: 0.2em;

		> mat-icon {
			margin-left: 0.2em;
		}
	}
}
// to apply this styles on cdk-drag-preview
.card-list-item {
	display: block;
	padding: 1.25rem $right-drawer-card-padding-x;

	position: relative;
	transition: box-shadow $transition-props;
	overflow: hidden;

	&:not(.cdk-drag-preview):not(.no-top-border) {
		border-top: 1px solid $color-border;
	}

	> .centered-space-between,
	&.centered-space-between {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&.hoverable {
		cursor: pointer;

		&:hover {
			@include elevation-5-y-zero();
		}
	}

	&:not(.hoverable) {
		cursor: default;
	}

	&.active {
		cursor: default !important;
		box-shadow: inset 0px 0px 13px -7px rgba(0, 0, 0, 0.5) !important;
	}
}
