@use '@angular/material' as mat;

a,
button {
	&.mat-button-base {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		min-width: auto;
		border-color: $color-border;
		transition-property: border-color, background, color, box-shadow;

		&.bp-button-xs {
			@include heightAndLineHeight(26px);
			padding: 0 12.5px;
			font-size: $font-size-sm;
			font-weight: $font-weight-normal;
		}

		&.bp-button-sm {
			@include heightAndLineHeight(30px);
			font-size: $font-size-md;
			padding: 0 12.5px;
			font-weight: $font-weight-normal;
		}

		&.bp-button-md {
			@include heightAndLineHeight(34px);
			font-size: $font-size-md;
			font-weight: $font-weight-normal;
		}

		&.bp-button-big {
			@include heightAndLineHeight(36px);
			font-size: $font-size-big;
			font-weight: $font-weight-normal;
		}

		&.bp-button-lg {
			@include heightAndLineHeight(40px);
			font-size: $font-size-lg;
		}

		&.bp-button-xl {
			@include heightAndLineHeight(42px);
			font-size: $font-size-xl;
			padding: 0 17.5px;
		}
	}
}

.bp-button.mat-button {
	border-radius: 30px;
	border: 2px solid $color-primary;
	color: $color-primary;
	transition: $animation-duration $animation-function;
	transition-property: background, color, box-shadow, opacity;
	padding: 0.2em 1.25em;
	min-width: 200px;

	&:hover {
		@include elevation(4, $elevation-color, 2);
		background: $color-primary;
		color: white;
	}

	&.primary {
		background-color: $color-primary;
		color: white;
	}

	bp-svg-icon {
		width: $icon-size;
		height: $icon-size;
		margin-right: 0.5em;
	}

	.mat-button-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.report-setup-pane & {
		border-color: white;
		color: white !important;
		transition-property: background, box-shadow, opacity;
	}

	&[disabled] {
		.bp-modal-overlay-pane.report-setup-pane & {
			background: $color-primary;
			color: white;
			opacity: 0.33;
		}

		&:not(.pending) {
			background: $color-lightgray;
			border-color: $color-lightgray;
		}

		&.pending {
			color: white;
		}

		cursor: not-allowed;
		box-shadow: none !important;
	}
}

a.mat-button.bp-button {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.bp-round-button {
	&.long-button {
		min-width: 200px;
	}

	&.mat-button-base {
		@include heightAndLineHeight(49px);
		padding: 0.175em 1.5em;
		border-radius: 49px;
		font-size: 16px;
		font-weight: $font-weight-bold;
	}

	&.mat-flat-button:not(.mat-button-disabled) {
		&.mat-primary {
			box-shadow: 0 10px 20px fade-out($color-primary, 0.82);

			&:hover {
				background-color: darken($color-primary, 5%);
			}
		}

		&.mat-warn {
			box-shadow: 0 10px 20px fade-out($color-danger, 0.82);

			&:hover {
				background-color: darken($color-danger, 5%);
			}
		}

		&.mat-accent {
			box-shadow: 0 10px 20px fade-out($color-accent, 0.82);

			&:hover {
				background-color: darken($color-accent, 5%);
			}
		}
	}

	mat-icon.bp-icon-xs {
		margin-bottom: 0.15rem;
	}
}

button.reset-default-decoration {
	padding: 0;
	background: transparent;
	border: none;
	text-align: left;
	outline: none;
}

.btn-icon {
	&.gray {
		color: $color-mediumgray;

		&:not([disabled]):hover {
			color: darken($color-mediumgray, 20%);
		}
	}

	&.warn-on-hover:not([disabled]):hover {
		color: mat.get-color-from-palette($danger-palette) !important;
	}
}
