@use '@angular/material' as mat;

.mat-button-toggle-group {
	&.button-toggle-group-rounded {
		border-radius: $round-control-border-radius !important;

		.mat-button-toggle {
			color: $color-dark-primary-text;
			background: none;

			+ .mat-button-toggle {
				border-left-color: #f0f0f0;
			}

			&.mat-button-toggle-checked {
				color: $color-primary;
				font-weight: $font-weight-bold;
			}

			.mat-button-toggle-label-content {
				line-height: $round-control-height !important;
			}
		}
	}

	&.dual-button-toggle-group-rounded {
		border-radius: $round-control-border-radius !important;
		border-color: $color-border;
		background: white;

		.mat-button-toggle {
			border-radius: $round-control-border-radius;
			border-color: transparent;
			overflow: hidden;
			color: $color-dark-primary-text;
			background: white;
			transition: background $transition-props;

			&.mat-button-toggle-checked {
				background: $color-primary;
				color: white;
				font-weight: $font-weight-extra-bold;
			}

			.mat-button-toggle-label-content {
				min-width: 111px;
				line-height: 41px !important;
			}

			.mat-button-toggle-focus-overlay {
				opacity: 0 !important;
			}

			&:not(.mat-button-toggle-disabled):not(.mat-button-toggle-checked):hover {
				background: mat.get-color-from-palette($primary-palette, 10);
			}
		}
	}

	&.button-toggle-group-flat {
		border-color: transparent;

		.mat-button-toggle {
			color: $color-dark-primary-text;
			background: none;
			border-radius: 4px;

			+ .mat-button-toggle {
				border-left-color: transparent;
			}

			&.mat-button-toggle-checked {
				color: $color-primary;
				font-weight: 500;
				overflow: hidden;
			}

			.mat-button-toggle-label-content {
				padding: 0 1rem;
			}

			.mat-button-toggle-focus-overlay {
				background-color: currentColor;
			}
		}
	}
}
