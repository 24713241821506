@use '@angular/material' as mat;

@mixin elevation-5-y-zero() {
	// as mat-elevation(5); but with the y offset set to 0
	box-shadow: 0px 0px 5px -1px rgba(0, 0, 0, 0.08), 0px 0px 8px 0px rgba(0, 0, 0, 0.056),
		0px 0px 14px 0px rgba(0, 0, 0, 0.048);
}

@mixin elevation-11-y-zero() {
	// as mat-elevation(11); but with the y offset set to 0
	box-shadow: 0px 0px 7px -4px rgba(0, 0, 0, 0.1), 0px 0 15px 1px rgba(0, 0, 0, 0.07),
		0px 0 20px 3px rgba(0, 0, 0, 0.06);
}

@mixin elevation($zValue, $color: $elevation-color, $opacity: $elevation-opacity) {
	@include mat.elevation($zValue, $color, $opacity);
}
