@use 'sass:math';

.cdk-global-overlay-wrapper {
	overflow: auto;
}

.mat-dialog-container {
	.btn-modal-icon-close {
		position: absolute;
		z-index: 2;
		$size: 60px;
		top: 27px;
		right: 27px;
		width: $size;
		height: $size;

		svg {
			width: math.div($size, 2);
			height: math.div($size, 2);
		}

		&:focus,
		&:hover {
			color: $color-dark;
		}
	}

	.mat-dialog-actions .mat-button-base:not(.mat-icon-button) {
		@extend .bp-round-button, .bp-button-big;
	}
}
