.mat-h4,
.mat-subheading-1,
h4 {
	text-transform: uppercase;
}

b,
strong {
	font-weight: $font-weight-bold;
}

.font-weight-extra-slim {
	font-weight: $font-weight-extra-slim !important;
}

.font-weight-slim {
	font-weight: $font-weight-slim !important;
}

.font-weight-normal {
	font-weight: $font-weight-normal !important;
}

.font-weight-bold {
	font-weight: $font-weight-bold !important;
}

.font-weight-extra-bold {
	font-weight: $font-weight-extra-bold !important;
}

.mat-h2,
.mat-title,
h2 {
	b {
		font-weight: 400;
	}
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

.text-primary {
	color: $color-primary;
}

.text-primary-light {
	color: $color-primary-light;
}

.text-primary-dark {
	color: $color-primary-dark;
}

.text-danger {
	color: $color-danger;
}

.text-warning {
	color: $color-warning-dark;
}

.text-mediumgray {
	color: $color-mediumgray;
}

.text-melrose {
	color: $color-melrose;
}

.text-slate-gray {
	color: $color-slate-gray;
}

.text-white {
	color: white;
}

.text-underline {
	text-decoration: underline;
}
