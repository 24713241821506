@use '@angular/material' as mat;

$color-mist                   : #eceff1 !default;
$color-lightboxgray           : #ebebeb !default;
$color-lightgray              : #ccc !default;
$color-mediumgray             : #929292 !default;
$color-darkgray               : #444444 !default;
$color-melrose                : #9999ff;
$color-indigo                 : #5e5ed3;
$color-fun-blue               : #1f5aab;
$color-blue-zodiac            : #0f213c;
$color-grey-mystic            : #d8dfea;
$color-slate-gray             : #7a8595;
$color-trout           	      : #4e5664;
$color-mist                   : #f9f9ff;
$color-mystic                 : #e9edf3;
$color-bittersweet            : #ff6666;
$color-spring-green           : #33ff99;
$color-mountain-meadow        : #22db7e;
$color-catskill-white         : #eef1f6;
$color-dark                   : #333 !default;
$color-titan-white            : #f7f7ff;

$color-border                 : #e8e8e8 !default;
$color-border-light           : $color-mystic !default;
$color-backdrop               : rgba(0, 0, 0, 0.25);
$color-table-cell-hover       : fade-out($color-mist, 0.3);
$color-disabled               : rgba(black, 0.38);
$color-primary                : #2578ea !default;
$color-primary-light          : #6ea6ff !default;
$color-primary-dark           : #004db7 !default;
$color-danger                 : $color-bittersweet !default;
$color-success                : $color-spring-green !default;

$color-bg                     : #fafafa !default;

$color-dark-primary-text      : $color-dark !default;
$color-faded-dark-primary-text: fade-out($color-dark-primary-text, 0.5);
// color with the alpha channel must be in hex for the css variables
$color-dark-secondary-text    : #0000008a !default;
$color-dark-disabled-text     : #00000061 !default;
$color-dark-dividers          : #0000001f !default;
$color-dark-focused           : #0000001f !default;
$color-light-primary-text     : white !default;
$color-light-secondary-text   : #ffffffb3 !default;
$color-light-disabled-text    : #ffffff80 !default;
$color-light-dividers         : #ffffff1f !default;
$color-light-focused          : #ffffff1f !default;

$blue-zodiac-palette: (
	50: #e5f0ff,
	100: #e5f0ff,
	500: $color-blue-zodiac,
	700: $color-blue-zodiac,
	contrast: (
		100: $color-dark-primary-text,
		500: $color-light-primary-text,
		700: $color-light-primary-text,
	),
);

$royal-blue-palette: (
	10: lighten($color-primary, 43%),
	50: lighten($color-primary, 35%),
	100: $color-primary-light,
	500: $color-primary,
	700: $color-primary-dark,
	contrast: (
		100: $color-dark-primary-text,
		500: $color-light-primary-text,
		700: $color-light-primary-text,
	),
);

$melrose-palette: (
	10: lighten($color-melrose, 10%),
	50: #dfdfff,
	100: #b6b6ff,
	500: $color-melrose,
	700: #5e5ed3,
	contrast: (
		100: $color-dark-primary-text,
		500: $color-light-primary-text,
		700: $color-light-primary-text,
	),
);

$spring-green-palette: (
	50: #eafff4,
	100: #eafff4,
	500: $color-success,
	600: #00e673,
	700: #00ca6a,
	contrast: (
		100: $color-dark-primary-text,
		500: $color-dark-primary-text,
		700: $color-dark-primary-text,
	),
);

$bittersweet-palette: (
	10: #fff3f3,
	50: lighten($color-danger, 24%),
	100: #ff9994,
	500: $color-danger,
	700: #c6323c,
	contrast: (
		100: $color-dark-primary-text,
		500: $color-light-primary-text,
		700: $color-light-primary-text,
	),
);

$primary-palette: mat.define-palette($royal-blue-palette) !default;
$accent-palette : mat.define-palette($spring-green-palette) !default;
$success-palette: mat.define-palette($spring-green-palette) !default;
$danger-palette : mat.define-palette($bittersweet-palette) !default;
$warning-palette: mat.define-palette(mat.$orange-palette) !default;
$info-palette   : mat.define-palette(mat.$light-blue-palette) !default;
$gray-palette   : mat.define-palette(mat.$gray-palette) !default;

$bp-colors-palette: (
	primary: $primary-palette,
	accent : $accent-palette,
	success: $success-palette,
	danger : $danger-palette,
	warning: $warning-palette,
	info   : $info-palette,
);

$color-accent                : mat.get-color-from-palette($accent-palette) !default;
$color-warning               : mat.get-color-from-palette($warning-palette) !default;
$color-warning-dark          : mat.get-color-from-palette($warning-palette, 800) !default;
$color-subscription-suspended: mat.get-color-from-palette($danger-palette, 700) !default;
$color-refund                : $color-melrose;

$filter-color-warning: grayscale(1) url(#warning-color-interpolation-filter) saturate(4);
