@use '@angular/material' as mat;

// #region layout
$toolbar-height  : 55px;
$layout-max-width: 1430px;
$layout-padding  : 40px;
$edit-max-width  : 1024px;
// #endregion layout

// #region mat-cards
$mat-card-padding-x            : 1rem;
$mat-card-padding-y            : 1.25rem;
$mat-card-status-border-width  : 5px;
$mat-card-grid-aisle           : 1.5rem;
$mat-card-grid-aisle-half      : 0.75rem;
// #endregion mat-cards

$elevation-opacity : 0.45;
$elevation-color   : black;
$elevation-panel   : 4;
$elevation-sidenav : 15;
$elevation-modal   : 10;
$elevation-card    : 5;
$elevation-card-aux: 3;

$fancy-shadow     : 0 40px 50px rgba(88, 92, 109, 0.2);
$fancy-shadow-mild: 0 15px 30px rgba(170, 170, 199, 0.2);
$control-shadow   : 0 6px 10px rgba(166, 173, 193, 0.1);

$mat-progress-bar-height: 6px;

// #region icons
$icon-size        : 24px;
$toolbar-icon-size: $icon-size;
// #endregion icons

// #region typography
$line-height-golden-ratio: 1.618;

$font-size-xs : 10px !default;
$font-size-sm : 12px !default;
$font-size-md : 14px !default;
$font-size-big: 16px !default;
$font-size-lg : 18px !default;
$font-size-xl : 22px !default;
$font-size-xxl: 24px !default;

$font-weight-extra-slim: 100 !default;
$font-weight-slim      : 300 !default;
$font-weight-normal    : 400 !default;
$font-weight-bold      : 500 !default;
$font-weight-extra-bold: 700 !default;

$font-family: 'Roboto,"Helvetica Neue",sans-serif' !default;

$headline-font-size : $font-size-xxl !default;
$headline-line-height: 32px !default;
$headline-font-weight: $font-weight-slim !default;

$title-font-size : $font-size-xl !default;
$title-line-height: 30px !default;
$title-font-weight: $font-weight-slim !default;

$subheading-2-font-size  : $font-size-lg !default;
$subheading-2-line-height: 28px !default;
$subheading-2-font-weight: $font-weight-normal !default;

$caption-font-size  : $font-size-sm !default;
$caption-line-height: 1.6666 !default;
$caption-font-weight: $font-weight-slim !default;

$body-font-size  : $font-size-md !default;
$body-line-height: 1.6666 !default;
$body-font-weight: $font-weight-normal !default;

$card-headline-font-size  : $font-size-lg !default;
$card-headline-line-height: 32px !default;
$card-headline-font-weight: $font-weight-normal !default;

$card-title-font-size  : $font-size-xl !default;
$card-title-line-height: 32px !default;
$card-title-font-weight: $font-weight-slim !default;

$button-font-size  : $icon-size !default;
$button-line-height: 32px !default;
$button-font-weight: $font-weight-slim !default;
// #endregion typography

// #region animation

$animation-duration-normal: 0.225s;
$animation-duration-fast  : 0.15s;
$animation-duration       : $animation-duration-normal;
$animation-function       : ease-in-out;
$transition-props         : $animation-duration $animation-function;

$flickering-animation: flickering 1.2s infinite cubic-bezier(0.45, 0.05, 0.55, 0.95);

// #endregion animation

// #region circle
$circle-md-size: 14px;
$circle-sm-size: 9px;
// #endregion circle

$dark-dividers                        : rgba(black, 0.05);
$card-border-radius                   : 10px;
$cdk-overlay-dark-backdrop-filter-blur: 10px;
$cdk-overlay-dark-backdrop-background : rgba(0, 0, 0, 0.05);

$popup-border-radius: 4px;
$popup-elevation    : 4;

$row-panel-cell-size     : 160px;
$row-panel-cell-head-size: 230px;

$thumbnail-size: 40px;

$card-logo-size: 75px;

$avatar-size: $thumbnail-size;

$z-index-sidenav: 1000000;
$z-index-max    : 2147483647;

$dialog-padding-y    : 24px !default;
$dialog-padding-x    : $dialog-padding-y * 1.5 !default;
$dialog-padding      : $dialog-padding-y $dialog-padding-x;
$dialog-header-margin: 1.5rem;
$dialog-footer-margin: 1.8rem;
$dialog-max-width-xs : 350px;
$dialog-max-width-sm : 400px;
$dialog-max-width-md : 600px;
$dialog-max-width-big: 700px;
$dialog-max-width-lg : 800px;

$modal-padding-y    : 32px;
$modal-padding-x    : 48px;
$modal-elevation    : 10;
$modal-max-width-xs : $dialog-max-width-xs;
$modal-max-width-sm : $dialog-max-width-sm;
$modal-max-width-md : $dialog-max-width-md;
$modal-max-width-big: $dialog-max-width-big;
$modal-max-width-lg : $dialog-max-width-lg;

$right-drawer-entity-header-height       : 70px;
$right-drawer-entity-headline-size       : $headline-font-size;
$right-drawer-entity-headline-font-weight: $headline-font-weight;
$right-drawer-card-padding-x             : 1.5rem;
$right-drawer-card-padding-y             : 1rem;
$right-drawer-card-margin-bottom         : 1.5rem;
$right-drawer-card-max-width             : 645px;
$right-drawer-card-max-width-lg          : 700px;

$round-control-border-radius: 35px;
$round-control-height       : 34px;

// according to http://google.github.io/material-design-icons/#styling-icons-in-material-design
$icon-size-xxs : 14px;
$icon-size-xs : 16px;
$icon-size-sm : 18px;
$icon-size-md : 24px;
$icon-size-big: 31px;
$icon-size-lg : 35px;
$icon-size-xl : 48px;
$icon-size-xxl : 58px;

$icons-sizes: (
	xxs: $icon-size-xxs,
	xs: $icon-size-xs,
	sm: $icon-size-sm,
	md: $icon-size-md,
	big: $icon-size-big,
	lg: $icon-size-lg,
	xl: $icon-size-xl,
	xxl: $icon-size-xxl,
);

$breakpoint-phone-xs     : 320px !default;
$breakpoint-phone        : 414px !default;
$breakpoint-phone-lg     : 550px !default;
$breakpoint-tablet       : 768px !default;
$breakpoint-laptop       : 1024px !default;
$breakpoint-widescreen   : 1280px !default;
$breakpoint-widescreen-lg: 1440px !default;

$swift-ease-out-duration       : 400ms !default;
$swift-ease-out-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;
$swift-ease-out                : all $swift-ease-out-duration $swift-ease-out-timing-function !default;

$border-low-dpi-1px-width-hack: 0.9999px;
