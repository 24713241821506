.select-field {
	display: flex;
	align-items: baseline;

	label {
		margin-right: 2rem;
		color: rgba(0, 0, 0, 0.6);
	}

	.control,
	mat-select {
		flex: 1;

		bp-input {
			width: 100% !important;
		}
	}
}

.mat-error {
	font-size: 11px;
}

div.mat-form-field {
	display: inline-flex;
	flex-flow: column;

	> mat-error {
		margin-top: 0.2em;
		margin-bottom: 0.2em;

		> bp-validation-error {
			min-height: 1em;
			margin: 0 !important;
		}
	}
}
