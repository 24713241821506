@use '@angular/material' as mat;

$error-message-font-size: 11px;

.form-field-appearance-round {
	> mat-form-field {
		width: 100%;

		mat-select .mat-select-value,
		input,
		.mat-form-field-prefix,
		.mat-form-field-suffix {
			position: unset !important;
			top: unset !important;
		}

		&:not(.mat-focused):not(.mat-form-field-disabled):not(.mat-form-field-invalid) {
			.mat-form-field-label {
				color: $color-slate-gray;
			}
		}

		&.ng-invalid.ng-dirty .mat-form-field-outline-thick {
			color: $color-danger;
		}

		.mat-form-field-outline {
			> div {
				background: white;
			}

			$start-min-width: 1.73333em;

			&-start {
				min-width: $start-min-width !important;
				border-radius: $start-min-width 0 0 $start-min-width !important;
			}

			&-end {
				border-radius: 0 $start-min-width $start-min-width 0 !important;
			}
		}

		.mat-form-field-flex {
			filter: drop-shadow($control-shadow);
			padding-left: 2em !important;
			padding-right: 2em !important;
		}

		.mat-form-field-infix,
		.mat-form-field-suffix {
			min-height: 3.184em !important; // 44px height of the input with 15px font size
		}

		.mat-form-field-infix {
			padding: 0.37499em 0 !important;
			width: 120px;
		}

		.mat-form-field-outline-gap,
		.mat-form-field-prefix,
		.mat-form-field-infix {
			margin-left: -0.3125em;
		}

		.mat-form-field-label-wrapper {
			top: -1.125em;
		}

		// Main label centering
		.mat-form-field-label {
			top: 1.75em !important;
		}

		// Floating label compensation
		&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
		&.mat-form-field-can-float .mat-input-server:focus + .mat-form-field-label-wrapper .mat-form-field-label {
			transform: translateY(-1.28125em) scale(0.75) !important;
		}

		.mat-select-arrow-wrapper {
			transform: translateX(80%) !important;
		}

		.mat-form-field-subscript-wrapper {
			margin-top: 0.3333em;
			padding: 0 2em;

			@include media('<phone-lg') {
				font-size: $error-message-font-size;
			}

			@include media('>=phone-lg') {
				padding: 0 2.3333em;
			}
		}

		mat-chip-list .mat-chip-list-wrapper {
			margin: -6px -16px 2px;
			gap: 0.5rem;

			input {
				&:first-child {
					padding-left: 1em;
					padding-top: 0.4em;
					margin: 0;
				}
				&:not(:first-child) {
					margin: 6px;
				}
			}

			.mat-standard-chip {
				margin: unset;
			}
		}

		&.mat-focused .mat-form-field-required-marker {
			color: mat.get-color-from-palette($danger-palette);
		}

		&.mat-form-field.mat-form-field-disabled {
			.mat-form-field-required-marker {
				color: mat.get-color-from-palette($danger-palette, 50);
			}
		}

		.mat-form-field-required-marker {
			color: mat.get-color-from-palette($danger-palette, 100);
		}

		bp-input-prefix {
			margin-right: 0 !important;
		}

		.mat-form-field-prefix {
			margin-left: -1em;
			margin-right: 0.9em;
		}

		&.has-prefix {
			.mat-form-field-outline-gap {
				margin-left: 0;
			}
		}

		&.error-hidden .mat-form-field-wrapper {
			padding: 0;
			margin: 0;
		}
	}

	@at-root bp-input#{&}:not(.form-field-appearance-round-lg)
			.mat-form-field.has-suffix
			.mat-form-field-flex
			.mat-form-field-suffix {
		margin: 0.25em 0.3333em 0;
	}

	@at-root bp-button-toggle#{&} {
		mat-button-toggle-group {
			border-radius: 50px;

			&,
			.mat-button-toggle {
				border-color: $color-border !important;
			}

			.mat-button-toggle-checked {
				background-color: $color-border;
			}
		}
	}

	&.form-field-appearance-round-lg {
		@include media('>=phone-lg') {
			mat-form-field.mat-form-field-appearance-outline {
				.mat-form-field-infix,
				.mat-form-field-suffix {
					min-height: 3.517em !important; // 44px height of the input with 15px font size
				}

				.mat-form-field-infix {
					padding: 0.56em 0 !important;

					.mat-form-field-label-wrapper {
						top: -0.95em;
					}
				}

				&.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
				&.mat-form-field-can-float
					.mat-input-server:focus
					+ .mat-form-field-label-wrapper
					.mat-form-field-label {
					transform: translateY(-1.42em) scale(0.75) !important;
				}

				.mat-form-field-subscript-wrapper {
					padding: 0 2.2em;
				}
			}

			mat-chip-list .mat-chip-list-wrapper {
				$offset: -12px;
				margin-left: $offset;
				margin-right: $offset;
			}
		}
	}
}

.form-field-compact > mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
	margin: 0.25em 0;
}

.mat-form-field bp-validation-error {
	font-size: $error-message-font-size;
}

.select-option-status .mat-option-text {
	display: flex;
	align-items: center;
	justify-content: space-between;

	&::first-letter {
		text-transform: capitalize;
	}
}
