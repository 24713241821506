@use '@angular/material' as mat;

.mat-form-field-label:not(.mat-form-field-empty) {
	transition: color $swift-ease-out-duration $swift-ease-out-timing-function !important;
}

.mat-form-field-appearance-standard,
.mat-form-field-appearance-legacy {
	.mat-form-field-underline {
		background-color: rgba(0, 0, 0, 0.12);
	}
}

mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper {
	margin: 0.25em 0 0.5em;
}

.mat-form-field {
	&.ng-dirty.ng-invalid {
		.mat-form-field-label {
			color: $color-danger;
		}

		.mat-form-field-ripple,
		.mat-form-field-underline {
			background-color: $color-danger;
		}

		input {
			caret-color: $color-danger;
		}
	}

	.mat-form-field-outline {
		transition: color $transition-props;
	}

	&.mat-focused .mat-form-field-required-marker {
		color: mat.get-color-from-palette($danger-palette);
	}
}

.mat-form-field.mat-form-field-disabled .mat-form-field-required-marker {
	color: mat.get-color-from-palette($danger-palette, 50);
}

.mat-form-field-required-marker {
	color: mat.get-color-from-palette($danger-palette, 100);
}

.mat-form-field .mat-nav-list .mat-list-item .mat-list-icon {
	padding: 0;
}

.mat-drawer {
	z-index: 10;
}

.mat-drawer-container,
.mat-card {
	color: $color-dark-primary-text;
}

.mat-drawer-container {
	background: $color-bg;
}

.mat-paginator {
	background: transparent;
}

.mat-ripple-element {
	background: fade-out($color-primary, 0.9);
}

mat-card.mat-card {
	border-radius: $card-border-radius;
	padding: $mat-card-padding-y $mat-card-padding-x;
	display: flex;
	flex-flow: column;
	overflow: hidden;
	position: relative;

	mat-card-title {
		margin-bottom: 1rem;
	}

	mat-card-content {
		flex: 1;

		> mat-progress-bar {
			position: absolute;
			top: 0;
		}
	}

	mat-card-footer {
		margin: 0 (-$mat-card-padding-x) (-$mat-card-padding-y) (-$mat-card-padding-x);
	}

	&:not([class*='mat-elevation-z']) {
		@include elevation($elevation-card);
	}
}

.mat-tooltip {
	font-size: $caption-font-size;
}

.mat-snack-bar-container {
	&.success {
		background: mat.get-color-from-palette($success-palette, 100);
		color: $color-dark-primary-text;

		.mat-simple-snackbar-action {
			color: $color-dark-primary-text;
		}
	}

	&.error {
		background: $color-danger;
		color: white;

		.mat-simple-snackbar-action {
			color: currentColor;
		}

		.mat-simple-snackbar {
			> span {
				margin: 0 auto;
			}
		}
	}
}
