table.details-table {
	width: 100%;

	tr td {
		&:first-child {
			font-weight: $font-weight-bold;
			padding: 0.25rem 0;
			vertical-align: top;
			width: 175px;

			& + td {
				max-width: 220px;
				overflow-wrap: break-word;
			}
		}

		&.status {
			white-space: nowrap;

			> .circle-sm {
				margin-right: 0.25rem;
			}
		}
	}
}

.page-table {
	th.text-center {
		text-align: center !important;

		.mat-sort-header-container {
			justify-content: center;
		}
	}

	td {
		&.mat-column-enabled,
		&.mat-column-menu,
		&.mat-column-status,
		&.mat-column-country {
			width: 1px;
		}

		&.mat-column-status,
		&.mat-column-enabled {
			padding-left: 0;
			padding-right: 15px;

			span {
				display: flex;
			}
		}

		&.mat-column-pspName,
		&.mat-column-logoUrl {
			padding-right: 15px;
		}

		&.mat-column-menu > button {
			margin-left: -15px;
		}

		&.mat-column-country {
			padding-left: 10px;
			padding-right: 17px;

			bp-country > span {
				display: flex;
				width: 1.75em;
				height: 1.5em;
				justify-content: center;
				align-items: center;
			}
		}
	}
}
