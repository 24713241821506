@use '@angular/material' as mat;

.intro-shell-page {
	display: flex !important;
	flex-direction: column;
	position: relative;
	align-items: center;
	justify-content: center;

	bp-footer,
	mat-card {
		width: 480px;
	}

	mat-card {
		box-shadow: $fancy-shadow !important;
		overflow: hidden;
		padding: 2rem 2rem 1.5rem;
		display: flex;
		min-height: 480px;
		border-radius: $card-border-radius;

		.logo-short-text {
			margin: 0 auto;
			color: mat.get-color-from-palette($primary-palette);
			height: 30px;
			width: 200px;
		}

		h1,
		mat-card-content > bp-sliding-step-routes {
			display: flex;
			justify-content: center;
		}

		h1 {
			@include mat.typography-level($core-typography-config, headline);
			margin: 1.5rem 0 2.5rem;
		}

		mat-card-content {
			font-size: 16px;

			> bp-sliding-step-routes {
				height: 100%;
			}

			router-outlet ~ * {
				width: 100%;
				height: 100%;
			}

			form {
				width: 100%;
				height: 100%;
				display: flex;
				flex-flow: column;

				.error {
					padding: 16px;
					width: 300px;
					color: white;
					background-color: red;
				}

				mat-form-field {
					font-size: 15px;
				}
			}

			.inputs-wrapper {
				display: flex;
				flex-flow: column;
				height: 100%;
				justify-content: center;
				margin-top: -1.5rem;
			}

			.aux-buttons {
				display: flex;
				justify-content: center;
				margin-top: 2rem;

				@include mat.typography-level($core-typography-config, body-2);
			}
		}
	}
}
