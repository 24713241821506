@mixin thick-scrollbar {
	&::-webkit-scrollbar {
		height: 6px;
		width: 6px;
	}
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	}
	&::-webkit-scrollbar-thumb {
		background-color: $color-mediumgray;
		outline: 1px solid $color-mediumgray;
	}
}

@mixin thin-scrollbar {
	&::-webkit-scrollbar {
		height: 4px;
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
	}

	&::-webkit-scrollbar-thumb {
		background-color: $color-lightgray;
		outline: 1px solid $color-lightgray;
	}
}
