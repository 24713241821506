@use '@angular/material' as mat;

.rule-type,
.status-live,
.status-hiring,
.status-published,
.status-true,
.status-in-process,
.status-capture-in-progress,
.status-active,
.status-enabled,
.status-pending,
.status-connected,
.status-processing,
.status-on-boarding {
	&[class*='status-bar'] {
		border-color: $color-primary !important;
	}

	&:not([class*='status-bar']) {
		background: $color-primary !important;
	}
}

.risk-high,
.payment-route-rule-type-block,
.status-declined-due-to-3-d-auth-failure,
.status-declined-do-not-try-again,
.status-declined-by-timeout,
.status-declined-due-to-invalid-credit-card,
.status-declined-due-to-invalid-data,
.status-declined-due-to-error,
.status-declined,
.status-suspended {
	&[class*='status-bar'] {
		border-color: $color-danger !important;
	}

	&:not([class*='status-bar']) {
		background: $color-danger !important;
	}
}

.status-refunded {
	&[class*='status-bar'] {
		border-color: $color-refund !important;
	}

	&:not([class*='status-bar']) {
		background: $color-refund !important;
	}
}

.status-voided,
.status-chargeback,
.status-on-boarding-blocked {
	&[class*='status-bar'] {
		border-color: black !important;
	}

	&:not([class*='status-bar']) {
		background: black !important;
	}
}

.risk-low,
.status-approved {
	&[class*='status-bar'] {
		border-color: $color-success !important;
	}

	&:not([class*='status-bar']) {
		background: $color-success !important;
	}
}

.status-filtered-by-rule {
	&[class*='status-bar'] {
		border-color: $color-mediumgray !important;
	}

	&:not([class*='status-bar']) {
		background: $color-mediumgray !important;
	}
}

.status-false,
.status-expired,
.status-init,
.status-inactive,
.status-disabled,
.status-not-hiring,
.status-not-published,
.status-not-connected,
.status-deleted {
	&[class*='status-bar'] {
		border-color: $color-lightgray !important;
	}

	&:not([class*='status-bar']) {
		background: $color-lightgray !important;
	}
}

.status-partly-refunded {
	background: $color-refund !important;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		@include size(100%);
		position: absolute;
		background: $color-success;
		transform: rotate(45deg) translateX(50%);
	}
}

.status-authorized,
.status-approved-on-hold {
	background: $color-primary !important;
	position: relative;
	overflow: hidden;

	&:after {
		content: '';
		@include size(100%);
		position: absolute;
		background: $color-success;
		transform: rotate(45deg) translateX(50%);
	}
}

.text-rule-type,
.text-primary,
.text-status-in-process,
.text-status-pending,
.text-status-active,
.text-status-connected,
.text-status-authorized,
.text-status-processing {
	color: $color-primary !important;
}

.text-payment-route-rule-type-block,
.text-status-declined-due-to-3-d-auth-failure,
.text-status-declined-do-not-try-again,
.text-status-declined-by-timeout,
.text-status-declined-due-to-invalid-credit-card,
.text-status-declined-due-to-error,
.text-status-declined {
	color: $color-danger !important;
}

.text-status-refunded {
	color: $color-refund;
}

.text-status-void,
.text-status-chargeback {
	color: black;
}

.text-status-enabled,
.text-status-approved,
.text-status-partly-refunded,
.text-success {
	color: mat.get-color-from-palette($success-palette, 700);
}

.text-status-init,
.text-status-inactive,
.text-status-disabled,
.text-status-not-connected {
	color: $color-mediumgray !important;
}

.risk-middle {
	background: $color-warning;
}
