@use '@angular/material' as mat;

@mixin pulsing-line($bottom: 0px) {
	&:before,
	&:after {
		content: '';
		height: 1px;
		background: $color-accent;
		position: absolute;
		bottom: $bottom;
		width: 100%;
		transform-origin: 50%;
		animation: pulsing 1.4s ease infinite;
	}

	&:after {
		transition: opacity $transition-props;
		opacity: 0;
		height: 2px;
	}
}

.control.ng-pending .mat-form-field,
.mat-form-field.form-field-pending {
	&.mat-form-field-appearance-outline {
		.mat-form-field-outline {
			@include pulsing-line;
		}

		&.mat-focused,
		&.mat-form-field-invalid,
		.mat-form-field-flex:hover {
			.mat-form-field-outline:after {
				opacity: 1;
			}
		}
	}

	&.mat-form-field-appearance-standard {
		.mat-form-field-underline {
			@include pulsing-line;
		}

		&.mat-focused .mat-form-field-flex,
		.mat-form-field-flex:hover,
		.mat-form-field-flex:focus {
			+ .mat-form-field-underline:after {
				opacity: 1;
			}
		}
	}

	&.mat-form-field-appearance-fill {
		.mat-form-field-underline {
			@include pulsing-line($bottom: 1px);
		}

		&.mat-focused .mat-form-field-flex,
		.mat-form-field-flex:hover,
		.mat-form-field-flex:focus {
			+ .mat-form-field-underline:after {
				bottom: 0;
				opacity: 1;
			}
		}
	}
}
