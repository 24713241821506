@use '@angular/material' as mat;

.circle {
	display: inline-block;
	@include size($circle-md-size);
	border-radius: $circle-md-size;
	transition: background $animation-duration $animation-function;
	background: black;

	&.primary {
		background: $color-primary;
	}

	&.accent {
		background: $color-accent;
	}

	&.success {
		background: $color-success;
	}

	&.warning {
		background: $color-warning;
	}

	&.danger {
		background: $color-danger;
	}
}

.circle-sm {
	@extend .circle;
	@include size($circle-sm-size);
}
