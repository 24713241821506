@use 'sass:math';

.mat-list-base {
	bp-nav-item,
	bp-nav-group {
		.nav-list-item {
			transition: background $animation-duration, color $animation-duration;
			color: $color-mediumgray;
			height: 60px;
			font-size: 20px;
			font-weight: $font-weight-slim;

			.mat-line {
				font-weight: $font-weight-slim;
				line-height: 30px;
			}

			.mat-list-icon + .mat-list-text {
				padding-left: math.div($layout-padding, 2);
			}

			.mat-list-item-content {
				padding: 0 $layout-padding;
			}
		}
	}
}
