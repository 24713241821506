.payment-option-brands {
	@include flex-flow-row-wrap(0.25rem);
	align-self: flex-start;
	margin-bottom: 0.6rem;
	margin-top: 0.25rem;
}

.three-d-secure {
	font-weight: $font-weight-extra-bold;
	color: #ea9114;

	&::first-letter {
		color: #c3110d;
	}
}

bp-payment-card-brand bp-svg-icon,
.boxed-value {
	border: 1px solid $color-lightboxgray;
	border-radius: 4px;
	background: white;
}

.boxed-value {
	padding: 1px 6px;
	font-size: 12px;
	display: inline-flex;
}
